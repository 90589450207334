import React, { Component, Suspense } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";

const ReactLazyPreload = importStatement => {
  const Component = React.lazy(importStatement);
  Component.preload = importStatement;
  return Component;
};

const Header = ReactLazyPreload(() => import("./components/Header/Header"));
const HeaderCommon = ReactLazyPreload(() => import("./components/Header/HeaderCommon"));
const Footer = ReactLazyPreload(() => import("./components/Footer/Footer"));
const HomePageComponent = ReactLazyPreload(() => import("./components/Home/Home"));
const CompanyPageComponent = ReactLazyPreload(() => import("./components/Company/Company"));
const IndustryPageComponent = ReactLazyPreload(() => import("./components/Industry/Industry"));
const PortfolioPageComponent = ReactLazyPreload(() => import("./components/Portfolio/Portfolio"));
const ServicePageComponent = ReactLazyPreload(() => import("./components/Service/Service"));
const ContactPageComponent = ReactLazyPreload(() => import("./components/Contact/Contact"));
const BlogPageComponent = ReactLazyPreload(() => import("./components/Blog/Blog"));
const BlogpostPageComponent = ReactLazyPreload(() => import("./components/Blog/Blogpost"));
const BlogsearchPageComponent = ReactLazyPreload(() => import("./components/Blog/Blogsearch"));
const BlogsinglePageComponent = ReactLazyPreload(() => import("./components/Blog/Blogsingle"));
const BlogpreviewPageComponent = ReactLazyPreload(() => import("./components/Blog/Blogpreview"));
const AccessibilityPageComponent = ReactLazyPreload(() => import("./components/Accessibility/Accessibility"));
const PrivacyPageComponent = ReactLazyPreload(() => import("./components/Privacy/Privacy"));
const DynamicPageComponent = ReactLazyPreload(() => import("./components/DynamicPage/DynamicPage"));
const DynamicPagePreviewComponent = ReactLazyPreload(() => import("./components/DynamicPage/DynamicPagePreview"));


export default class App extends Component {
  state = {
    site_postfix: "",
    site_url: ""
  };

  render() {
    
    return (
      <React.Fragment>
      <Suspense fallback={<div>&nbsp;</div>}>
        <Router>
          <Switch>
            <Route exact path="/">
              <Header urls={this.state} />
              <HomePageComponent urls={this.state} />
              <Footer urls={this.state} />
            </Route>

            <Route exact path="/company">
              <HeaderCommon urls={this.state} />
              <CompanyPageComponent urls={this.state} />
              <Footer urls={this.state} />
            </Route>

            <Route exact path="/industries">
              <HeaderCommon urls={this.state} />
              <IndustryPageComponent urls={this.state} />
              <Footer urls={this.state} />
            </Route>

            <Route exact path="/portfolio">
              <HeaderCommon urls={this.state} />
              <PortfolioPageComponent urls={this.state} />
              <Footer urls={this.state} />
            </Route>

            <Route exact path="/services">
              <HeaderCommon urls={this.state} />
              <ServicePageComponent urls={this.state} />
              <Footer urls={this.state} />
            </Route>

            <Route exact path="/contact">
              <HeaderCommon urls={this.state} />
              <ContactPageComponent urls={this.state} />
              <Footer urls={this.state} />
            </Route>

            <Route exact path="/accessibility-policy">
              <HeaderCommon urls={this.state} />
              <AccessibilityPageComponent urls={this.state} />
              <Footer urls={this.state} />
            </Route>
            <Route exact path="/privacy-policy">
              <HeaderCommon urls={this.state} />
              <PrivacyPageComponent urls={this.state} />
              <Footer urls={this.state} />
            </Route>

            <Route exact path="/blog">
              <HeaderCommon urls={this.state} />
              <BlogPageComponent urls={this.state} />
              <Footer urls={this.state} />
            </Route>


            <Route exact path="/blog/category/:slug" render={(props) => (<>
              <HeaderCommon urls={this.state} />
              <BlogpostPageComponent urls={this.state} {...props} />
              <Footer urls={this.state} />
              </>)}>
            </Route>

            <Route exact path="/blog/:slug" render={(props) => (<>
              <HeaderCommon urls={this.state} />
              <BlogsinglePageComponent urls={this.state} {...props} />
              <Footer urls={this.state} />
              </>)}>
            </Route>

            <Route exact path="/blog/preview/:slug" render={(props) => (<>
              <HeaderCommon urls={this.state} />
              <BlogpreviewPageComponent urls={this.state} {...props} />
              <Footer urls={this.state} />
              </>)}>
            </Route>

            <Route exact path="/blog/search/:slug" render={(props) => (<>
              <HeaderCommon urls={this.state} />
              <BlogsearchPageComponent urls={this.state} {...props} />
              <Footer urls={this.state} />
              </>)}>
            </Route>

            <Route exact path="/page/preview/:slug" render={(props) => (<>
              <HeaderCommon urls={this.state} />
              <DynamicPagePreviewComponent urls={this.state} {...props} />
              <Footer urls={this.state} />
              </>)}>
            </Route>

            <Route exact path="/:slug" render={(props) => (<>
              <HeaderCommon urls={this.state} />
              <DynamicPageComponent urls={this.state} {...props} />
              <Footer urls={this.state} />
              </>)}>
            </Route>

            
          </Switch>
        </Router>
      </Suspense>
      </React.Fragment>
    )
  }
  
}